import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../../components/image.js";
export const query = graphql`
  query {
    batteryplacements: file(relativePath: { eq: "batteryplacements.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "elcykelbatteri"
    }}>{`Elcykelbatteri`}</h1>
    <p>{`Batteriet i en elcykel är en av de viktigaste komponenterna, så det är den som driver motorn. Men hur stor skillnad är det egentligen mellan olika batterier? Och vad betyder de olika värdena Wh, Ah och Volt? I guiden nedan försöker vi reda ut alla begrepp och ge dig den kunskap du behöver för att kunna utvärdera batteriet i en elcykel.`}</p>
    <h2 {...{
      "id": "litium-batteri"
    }}>{`Litium-batteri`}</h2>
    <p>{`I Sverige används nästan uteslutande litium-batterier i elcyklar. Det finns olika varianter av litium-batterier, den vanligaste bland cyklar är litium-jon. En annan variant är litium-polymer som tillåter att man kan forma batteriet bättre men är däremot dyrare att tillverka och har inte riktigt samma livslängd. Anledningen till att litium-batterier är så vanliga är att de har hög energidensitet, dvs mycket energi per vikt, samt att de tål att laddas väldigt många gånger. Det är inte helt ovanligt att man enligt tillverkare kan ladda deras batterier upp till 1000 gånger. Utöver att användas i elcyklar används också litium-batterier i till exempel mobiltelefoner, bärbara datorer och elbilar.`}</p>
    <p>{`Ett batteri är uppbyggt av flera battericeller, och samma typ av battericeller kan användas i såväl bärbara datorer som elbilar.`}</p>
    <h2 {...{
      "id": "batteriets-position"
    }}>{`Batteriets position`}</h2>
    <p>{`Batteriet kan placeras på olika ställen på cykeln, de vanligaste är vid pakethållaren, på sadelröret eller på diagonalröret. De olika positionerna har ibland nackdelar som kan vara bra att fundera över. Kommer tillexempel batteriet hindra att du sätter på en barnstol på cykeln?`}</p>
    <div className="reponsive_image">
  <Image fixed={props.data.batteryplacements.childImageSharp.fixed} alt="Batteriets olika positioner. Pakethållaren, sadelröret eller diagonalröret." title="Tre olika placeringar av alternativet" rowOne="De tre vanligaste ställena att placera cykelbatteriet." rowTwo="Vid pakethållaren, på sadelröret eller på diagonalröret." rowThree="Alla placeringar har sina för- och nackdelar." mdxType="Image" />
    </div>
    <h3 {...{
      "id": "pakethållaren"
    }}>{`Pakethållaren`}</h3>
    <p>{`Den absolut vanligaste positionen är vid pakethållaren. Ofta är det konstruerat så att batteriet sitter under pakethållaren så att du fortfarande har en fult fungerande pakethållare. Detta medför dock att pakethållaren blir lite högre vilket i vissa fall gör att du inte får plats med en barnstol som oftast fästs på cykelns sadelrör via två stänger. Kolla gärna med återförsäljaren, vissa har provat ut kompatibla barnstolar som funkar med cykeln. Då batteriet väger en del så kan ett batteri på pakethållaren även medföra att den totala vikten du kan lasta på pakethållaren sänks.`}</p>
    <h3 {...{
      "id": "sadelstolpen"
    }}>{`Sadelstolpen`}</h3>
    <p>{`Nackdelarna med ett batteri vid pakethållaren har gjort att vissa tillverkare väljer att placera det bakom sadelröret. Detta frigör yta vid pakethållaren och tillåter att en vanlig pakethållare används. Tyvärr fästs numera de flesta barnstolar också på sadelröret vilket gör att det sällan går att använda dessa på cyklar med batteriet placerat där. En fördel mot pakethållaren är att det tunga batteriet hamnar längre ner och cykelns tyngdpunkt blir därmed lägre vilket gör att cykeln blir stabilare. Dock gör batteripositionen att bakhjulet måste flyttas lite bakåt och cykeln blir någon decimeter längre. Många upplever då att cykelkänslan försvinner och denna batteriplacering blir mindre och mindre vanlig.`}</p>
    <h3 {...{
      "id": "diagonalröret"
    }}>{`Diagonalröret`}</h3>
    <p>{`En bra kompromiss mellan pakethållaren och sadelstolpen är att placera batteriet på diagonalröret. Då får fördelen med den låga tyngdpunkten samtidigt som du varken behöver påverka pakethållaren eller längden på cykeln. Denna position är till exempel väldigt vanlig på mountain bikes då dessa i regel saknar pakethållare. Även hybridcyklar brukar ha denna position för att pakethållaren inte ska på verkas samt att många anser att cykeln ser lite modernare ut.`}</p>
    <h2 {...{
      "id": "v-ah-wh-vad-betyder-förkortningarna"
    }}>{`V, Ah, Wh? Vad betyder förkortningarna?`}</h2>
    <p>{`När man läser specifikationerna för en elcykel, eller dessa batterier, så benämns ofta kapaciteten med hjälp av V, Ah och Wh. För att förstå dessa behöver vi förstå tre begrepp som används inom ellära:`}</p>
    <ul>
      <li parentName="ul">{`Spänning, som mäts i volt (V) kan beskrivas som kraften som trycker ut strömmen. Ju högre spänning, ju högre kraft och desto starkare motor kan man driva. 24V, 36V och 48V är det vanligaste för elcyklar, där en klar majoritet använder 36V.`}</li>
      <li parentName="ul">{`Ström, som mäts i ampere (A), anger hur mycket ström som flödar varje sekund.`}</li>
      <li parentName="ul">{`Effekten, som mäts i watt (W), är ett mätvärde som beskriver hur mycket energi som går åt varje sekund. Effekten beräknas som W = V `}{`*`}{` A, dvs ökar vi spänningen eller strömmen, så ökar också effekten då mer energi används varje sekund.`}</li>
    </ul>
    <p>{`Så var betyder då V, Ah och Wh? V (volt) beskriver helt enkelt vilken spänning cykelns batteri har, och det är även denna spänningen som cykelns andra komponenter är anpassade för (motor, display, styrenhet m.m.). Denna är nästan alltid 36V.`}</p>
    <p>{`Ah, ampere-timmar, anger hur många ampere ett batteri klarar av att leverera under en timma, innan batteriet är slut. 10Ah betyder att batteriet kan trycka ut 10 ampere under 1h. Dock säger inte denna siffra så mycket utan att veta spänningen. Och det är även svårt att få fram räckvidden från denna siffra.`}</p>
    <p>{`Wh, eller wattimmar, är det mått som är viktigast att kolla på när du kollar kapaciteten på din cykel. Wh beräknas som V `}{`*`}{` Ah och anger vilken effekt ditt batteri kan leverera under en timme innan batteriet är slut. Står det till exempel 500Wh så kan cykeln leverera 500W under 1h, eller 250W under 2h osv. Då de flesta cyklar är på 250W så vet du att om batteriet är på 500Wh så kan du cykla i 2h på full effekt. Dock driver inte alltid motorn på 250W, det kan tillexempel vara en i nerförsbacke så motorn inte behöver använda sin fulla kapacitet. Så i regeln håller cykeln mycket längre.
Batteriets räckvidd
I slutändan handlar nästan allting om hur långt du kommer på en laddning. Ska du cykelpendla till jobbet och du har 20km till jobbet, så vill du att cykeln ska klara 40km med god marginal, eller 20km om du har möjlighet att ladda på jobbet. Men när det kommer till att mäta räckvidden och specifikationerna från tillverkaren så kan det bli väldigt lurigt. Batteriets, eller cykelns, räckvidd bestäms inte enbart utav batterietstorleken och motoreffekten, utan det påverkas även av yttre effekter så som terräng, backar och temperatur. Även batteriets ålder påverkar då ett batteri tappar kapacitet när det blir äldre, mer om detta nedan. Utöver detta gillar vissa personer att hjälpa till mycket själv för att förlänga räckvidden medan andra vill ta det lugnt och låta cykeln göra jobbet.`}</p>
    <p>{`Med hänsyn till detta så anges därför nästan alltid räckvidden i ett spann. 30km – 60km är ganska vanligt för cyklar i Sverige. I -10 grader och mycket backar kan du då inte räkna med att komma längre än 30km, men är det 15 grader och plan mark kan du i bästa fall ta dig så långt som 60km. Men detta gäller endast när batteriet är nytt. Ju mer du använder det och laddar det, ju mer slits det och tappar kapacitet. Efter 1000 laddcykler (totala urladdningar och fulladdningar) kan du räkna med att batteriet endast har en kapacitet på 60% av sin ursprungliga kapacitet. Räckvidden har då också sänkts med motsvarande siffra. För att maximera batteriets livslängd finns det ett gäng saker man kan tänka på vilket vi går igenom nedan.`}</p>
    <h2 {...{
      "id": "laddning-förvaring-och-underhåll"
    }}>{`Laddning, förvaring och underhåll`}</h2>
    <p>{`En vanlig missuppfattning är att batteriet ska vara helt slut innan man laddar det, och att man sedan ska ladda det helt fult igen innan man börjar använda det. Detta är en kvarleva från äldre batterityper som krävde denna rutin. För litium-batterier behöver man inte tänka på detta utan du kan ladda när du vill, även om det endast är korta stunder. Ofta pratar man om livslängd i termer av laddcykler, t.ex. att batteriet klarar 1000 laddcykler. En laddcykel betyder att batteriets har laddats 100%. Sen om detta är en gång från 0% till 100% eller fyra gånger från 25% till 50% spelar ingen roll. Batteriets slits lika mycket oavsett.`}</p>
    <p>{`En annan missuppfattning är att man ska dra ur laddaren så fort batteriet är fulladdat, samt aldrig köra batteriet tills det blir helt tomt. Det stämmer att man egentligen aldrig ska köra ett batteri tills det är helt tomt, då det kräver lite energi kvar för att få i gång en laddning. Men batteriet har inbyggd säkerhet som gör att det stänger av innan det är helt tomt. Så när du ser 0% på displayen, så är det egentligen kanske 10% kvar fast batteriet väljer att stänga av för att skydda sig. När det gäller att dra ur laddaren när batteriet är laddat, så finns det även här inbyggd övervakning och laddaren slutar ladda när batteriet är fullt.`}</p>
    <p>{`Här kommer ett gäng punkter som du kan tänka på för att maximera din livslängd.`}</p>
    <ul>
      <li parentName="ul">{`Batteriet mår bäst när det är 40%-60% fullt. Ska du därför inte använda det under en lång tid så koppla ur det ur laddare när det är ungefär halvfullt.`}</li>
      <li parentName="ul">{`Ladda aldrig batteriet när det är kallt, ta i stället in det inomhus och låt det tina lite innan du kopplar in det i laddaren.`}</li>
      <li parentName="ul">{`Ladda inte heller batteriet om det är för varmt. Det kan bli för varmt om det är väldigt varmt ute och du precis har cyklat. Låt då batteriet svalna lite inomhus innan du kopplar in det i laddaren.`}</li>
      <li parentName="ul">{`Ladda när du har möjlighet och bry dig inte om om det är helt tomt innan du börjar ladda.`}</li>
      <li parentName="ul">{`Ska du inte använda det på ett tag, förvara det då svalt och mörkt. Går det väldigt lång tid kan du underhållsladda det då batteriet tappar även om det inte används. Detta gör du genom att koppla in det i laddaren och ladda tills det är cirka 50% fullt.`}</li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      